export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error) => {
    throw createError({
      statusCode: 404,
      message: error instanceof Error ? error.message : 'An error occurred',
    })
  }

  nuxtApp.hook('vue:error', (error) => {
    throw createError({
      statusCode: 404,
      message: error instanceof Error ? error.message : 'An error occurred',
    })
  })
})
