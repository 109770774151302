import { acceptHMRUpdate } from 'pinia'

export const useNavStore = defineStore('nav', () => {
  const config = useRuntimeConfig()
  const mainStore = useMainStore()
  const numberOfJobPositions = ref(0)

  // const simpleNavButtonText = computed(() => {
  //   return mainStore.storyData?.content?.button_text || 'Get in touch'
  // })

  const simpleNavButtonLink = computed(() => {
    const buttonLink = ref(mainStore.storyData?.content?.button_link || null)
    return {
      link: buttonLink.value,
      target: buttonLink.value?.target || '_self',
    }
  })

  // const naviColor = computed(() => {
  //   return mainStore.storyData?.content?.nav_color?.value || '#fff'
  // })

  const fetchNumberOfPositions = async () => {
    const rawData = await fetch(`${config.public.leverCareersUrl}?&mode=json`)
    numberOfJobPositions.value = (await rawData.json()).length
  }

  return {
    fetchNumberOfPositions,
    numberOfJobPositions,
    // naviColor,
    // simpleNavButtonText,
    simpleNavButtonLink,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNavStore, import.meta.hot))
}
