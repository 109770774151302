import type { Pinia } from 'pinia'

export default defineNuxtPlugin(async (nuxtApp) => {
  const mainStore = useMainStore(nuxtApp.$pinia as Pinia)
  const datasourceStore = useDataSourceStore(nuxtApp.$pinia as Pinia)
  const navStore = useNavStore(nuxtApp.$pinia as Pinia)
  const cacheStore = useCacheStore(nuxtApp.$pinia as Pinia)

  const storyblokApi = useStoryblokApi()
  const config = useRuntimeConfig()

  const version = config.public.storyblokApiVersion as 'draft' | 'published'
  const i18n = nuxtApp.$i18n as any
  const language = i18n.locale.value === 'en' ? '' : i18n.locale.value

  try {
    const [datasource, floatingPromo] = await Promise.all([
      storyblokApi.get('cdn/datasource_entries', {
        datasource: 'global-values',
        version,
        cv: cacheStore.cacheVersion,
      }),
      storyblokApi.get('cdn/stories/global/floating-promo', {
        version,
        language,
        cv: cacheStore.cacheVersion,
      }),
      !navStore.numberOfJobPositions && navStore.fetchNumberOfPositions(),
    ])

    datasourceStore.$patch({
      globalDatasource: datasource.data.datasource_entries,
    })
    mainStore.$patch({
      promo: floatingPromo.data,
    })
  } catch (err: any) {
    !err
      ? createError({
          statusCode: 404,
          message: 'Failed to receive promo data form api',
        })
      : createError({
          statusCode: err.status,
          message: err.message,
        })
  }
})
