import revive_payload_client_rrUOY4fSd7 from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iMCSZmGHRJ from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_g0TMiRWDAR from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1FrEG3yrF3 from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.20.0_vite@5.3.5_vue@3.4.35/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_Y9K1DkgoJV from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jLM8nQQacu from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zmPyvPQohS from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_vLnTA55vrK from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.2_rollup@4.20.0_typescript@5.5.4_vue@3.4.35/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_qe5Cr8lpyQ from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.8.0_rollup@4.20.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_KUk0tUVLNW from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_rollup@4.20.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_NKQ3DIIoMW from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_jT8HISAb11 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_Qa1h2am6Do from "/app/node_modules/.pnpm/@storyblok+nuxt@6.0.10/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import motion_F4L9jfnRYU from "/app/node_modules/.pnpm/@vueuse+motion@2.2.3_rollup@4.20.0_vue@3.4.35/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_CFCWXwuulQ from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.20.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import clickOutsideDirective_JuSoe6vGeC from "/app/plugins/clickOutsideDirective.ts";
import error_handler_kEP5FliEXj from "/app/plugins/error-handler.ts";
import fetchGlobalData_EOfhKKMol6 from "/app/plugins/fetchGlobalData.ts";
import onLanguageSwitch_Zq3lupusSn from "/app/plugins/onLanguageSwitch.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_rrUOY4fSd7,
  unhead_iMCSZmGHRJ,
  router_g0TMiRWDAR,
  _0_siteConfig_1FrEG3yrF3,
  navigation_repaint_client_Y9K1DkgoJV,
  check_outdated_build_client_jLM8nQQacu,
  chunk_reload_client_zmPyvPQohS,
  plugin_vue3_vLnTA55vrK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qe5Cr8lpyQ,
  nuxt_plugin_KUk0tUVLNW,
  switch_locale_path_ssr_NKQ3DIIoMW,
  i18n_jT8HISAb11,
  plugin_Qa1h2am6Do,
  motion_F4L9jfnRYU,
  plugin_CFCWXwuulQ,
  clickOutsideDirective_JuSoe6vGeC,
  error_handler_kEP5FliEXj,
  fetchGlobalData_EOfhKKMol6,
  onLanguageSwitch_Zq3lupusSn,
  sentry_client_shVUlIjFLk
]