import { BREAKPOINT } from '@/data/constants'
import type { DeviceModule, ScriptMeta } from '@/types'

export const scrollTo = (elementSelector = '#form-section', offset = 0) => {
  if (document.querySelector(elementSelector)) {
    const element = document.querySelector(elementSelector)
    if (element !== null) {
      const position =
        element.getBoundingClientRect().top + window.scrollY - offset
      window.scrollTo({
        top: position,
        behavior: 'smooth',
      })
      return
    }
  }
  console.error('No selector provided')
}

export const getCookie = (name: string) => {
  const parts = `; ${document.cookie}`.split(`; ${name}=`)
  return parts.length !== 2 ? undefined : parts.pop()?.split(';').shift()
}

export const capitalizeString = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

export const slugify = (positionName: string) =>
  positionName && positionName.replace(/\s+/g, '-').toLowerCase()

export const isArrayEmpty = (array: any[]) =>
  Array.isArray(array) && !array.length

export const addSlashInLink = (link: string) =>
  link.startsWith('/') ? link : `/${link}`

export const removeTrailingSlash = (str: string) =>
  str.endsWith('/') ? str.slice(0, -1).trim() : str?.trim()

export const getComputedStyle = (style: string, element: Element) => {
  const compStyles = window.getComputedStyle(element)
  return parseInt(compStyles.getPropertyValue(style), 10)
}

export const setDefaultDocumentWidth = (deviceModule: DeviceModule): number => {
  if (deviceModule.isMobile) {
    return BREAKPOINT.SM
  }

  if (deviceModule.isTablet) {
    return BREAKPOINT.MD
  }
  return BREAKPOINT.LG
}

export const removeAttributes = (element: HTMLElement) => {
  const finalElement = element
  while (finalElement.attributes.length > 0) {
    element.removeAttribute(element.attributes[0].name)
  }

  return finalElement
}

export const formatDate = (date: Date) => {
  const [day, month, year, hours, minutes] = [
    date.getDate(),
    date.getMonth() + 1,
    date.getFullYear(),
    date.getHours(),
    date.getMinutes(),
  ]

  const formattedDate = `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  } ${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`

  return formattedDate
}

// export const unref2 = (r: any) => (isRef(r) ? r.value : r)

export const getCurrentLocale = (i18n: any) => {
  return i18n.locale.value === 'en' ? '' : `/${i18n.locale.value}`
}

export const onLoad = (callback: Function, delay = 1) => {
  if (document.readyState === 'complete') {
    setTimeout(() => callback(), delay)
  } else {
    window.addEventListener('load', function () {
      setTimeout(() => callback(), delay)
    })
  }
}

export const appendScriptToHead = (scriptData: ScriptMeta) => {
  if (!document) {
    console.error("Can't append script to head, no document found.")
    return
  }

  const scriptEl = document.createElement('script')

  if (scriptData.src) scriptEl.src = scriptData.src
  if (scriptData.innerHTML) scriptEl.innerHTML = scriptData.innerHTML

  scriptEl.type = scriptData.type || 'text/javascript'
  scriptEl.async = scriptData?.async || false

  const range = document.createRange()
  document.head.appendChild(range.createContextualFragment(scriptEl.outerHTML))
}
export const appendScriptToBody = (scriptData: ScriptMeta) => {
  if (!document) {
    console.error("Can't append script to body, no document found.")
    return
  }

  const scriptEl = document.createElement('script')

  if (scriptData.src) scriptEl.src = scriptData.src
  if (scriptData.innerHTML) scriptEl.innerHTML = scriptData.innerHTML

  scriptEl.type = scriptData.type || 'text/javascript'
  scriptEl.async = scriptData?.async || false

  const range = document.createRange()
  document.body.appendChild(range.createContextualFragment(scriptEl.outerHTML))
}

export const removeHTMLTags = (html: string) => {
  const regex = /<\/?([a-z]+)(?![^>]*\/>)[^>]*>/g
  return html.replace(regex, '')
}

export const removeHTMLListTags = (html: string) => {
  const regex = /<\/?(li+)(?![^>]*\/>)[^>]*>/g
  return html.replace(regex, '')
}

export const checkIfElementIsInViewport = (el: HTMLElement, fullyVisible = false) => {
  const { top, bottom, left, right } = el.getBoundingClientRect()
  const vHeight = window.innerHeight || document.documentElement.clientHeight
  const vWidth = window.innerWidth || document.documentElement.clientWidth

  if (fullyVisible) {
    return top >= 0 && bottom <= vHeight && left >= 0 && right <= vWidth
  }

  return (top > 0 || bottom > 0) && top < vHeight
}
